import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Summary from './Summary';
import Ranking from './Ranking';
import Lineup from './Lineup';
import Rules from './Rules'
import getLPTheme from './getLPTheme';
import ResponsiveAppBar from './components/MenuBar'
import SplashScreen from "./components/SplashScreen";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://kupala-tri.com/">
        Kupala Tri
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const USER_ID_KEY = 'userid';

export default function Dashboard() {
  
  const [activeComponent, setActiveComponent] = React.useState('Weekly Lineup');
  const defaultTheme = createTheme(getLPTheme('light'));
  const [userid, setUserid] = React.useState(() => {
    const savedUserid = localStorage.getItem(USER_ID_KEY);
    return savedUserid ? parseInt(savedUserid, 10) : 0; 
  });

  React.useEffect(() => {
    localStorage.setItem(USER_ID_KEY, userid.toString()); 
  }, [userid]);

  return (
    <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        { userid === 0 && <SplashScreen />}

        <MuiAppBar position="absolute" >
          <ResponsiveAppBar setActiveComponent = {setActiveComponent} userid = {userid} setUserid = {setUserid} />
        </MuiAppBar>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container sx={{ mt: 2, mb: 1, mx: 2 }}>

            {activeComponent === 'Season Summary' && <Summary  userid={userid} />}
            {activeComponent === 'Weekly Lineup' && <Lineup userid={userid}/>}            
            {activeComponent === 'Leaderboard' && <Ranking userid={userid}/>}            
            {activeComponent === 'How To Play' && <Rules/>}            
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      {/* </Box> */}
    </ThemeProvider>
  );
}
