import React, { useState, useEffect } from 'react';
import { Button, Card, CardContent, Grid, Typography, Paper, Box, Avatar, Stack, Alert } from '@mui/material';
import axios from 'axios';
import StatusBar from './components/StatusBar';
import AthleteProfile from './components/AthleteProfile';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Triathletes from './components/SelectTriathletes';

const ActiveSelection = ({ eventInfo }) => {
  return (
    <Stack spacing={1} sx={{ maxHeight: "100%", overflow: "auto" }}>
      {/* This Week's Competitions */}
      <Box>
        <Box  sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
          <Typography variant="h6">This Week's Competitions</Typography>
          {eventInfo.locked ? (
            <Alert severity="error" sx={{ minWidth: "fit-content", py: 0, px: 2 }}>
              Period Closed
            </Alert>
          ) : (
            <Alert severity="success" sx={{ minWidth: "fit-content", py: 0, px: 2 }}>
              Changes until {eventInfo.lock_time}
            </Alert>
          )}
        </Box>

        <Box mt={0}>
          {eventInfo.competitions.length > 0 ? (
            eventInfo.competitions.map((competition, index) => (
              <Typography key={index} variant="body2">
                {competition} 
              </Typography>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              No competitions this week.
            </Typography>
          )}
        </Box>
      </Box>

      {/* Upcoming Competitions */}
      <Box>
        <Typography variant="h6">Upcoming Competitions</Typography>
        <Box mt={0}>
          {eventInfo.upcoming?.length > 0 ? (
            eventInfo.upcoming.map((competition, index) => (
              <Typography key={index} variant="body2">
                {competition}
              </Typography>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              No upcoming competitions.
            </Typography>
          )}
        </Box>
      </Box>
    </Stack>
  );
};

export default function Lineup({ userid }) {
  const [pickedAthletes, setPickedAthletes] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [status, setStatus] = useState({ message: '', type: '' });
  const [eventInfo, setEventInfo] = useState({ competitions: [], upcoming:[], end: '', locked: false });
  const [openAthleteProfile, setOpenAthleteProfile] = useState(false);
  const [profileAthlete, setProfileAthlete] = useState(0);
  const [openSelectTriathletes, setOpenSelectTriathletes] = useState(false);

  useEffect(() => {
    fetch('/api/current_events')
      .then((res) => res.json())
      .then((data) => {
        setEventInfo(data)
        });
  }, []);

  const pickSelectedAthletes = async (selected) => {
    try {
      const res = await axios.post('/api/pick/manual', { user: userid, ids: selected });
      setPickedAthletes(res.data.selected || []);
      setStatus({ message: res.data.message, type: 'success' });
    } catch (error) {
      console.error('Error updating athletes:', error.response.data);
      setStatus({ message: error?.response?.data, type: 'error' });
    }
  };

  useEffect(() => {
    fetch(`/api/selected_athletes/${userid}`)
      .then((res) => res.json())
      .then((data) => setPickedAthletes(data));
  }, [userid]);

  const autoPick = async () => {
    setProcessing(true);
    try {
      const res = await axios.post('/api/pick/auto', { user: userid });
      setPickedAthletes(res.data.selected || []);
      setStatus({ message: res.data.message, type: 'success' });
    } catch (error) {
      setStatus({ message: error?.response?.data, type: 'error' });
    } finally {
      setProcessing(false);
    }
  };

  const handleRemoveAthlelte = async (athlete_id) => {
    setProcessing(true);
    try {
      await axios.post('/api/pick/clear', {id:athlete_id, user: userid });
      setPickedAthletes((prevRows) => {
        return prevRows.filter((row) => row.id !== athlete_id);
      })
    }
    catch (error) {
      setStatus({ message: error?.response?.data, type: 'error' });
    } finally {
      setProcessing(false);
    }
  }
  
  const fillEmptyCards = (data, total) => {
    const filledData = [...data];
    while (filledData.length < total) {
      filledData.push({ id: `empty-${filledData.length}`, name: 'Not Selected' });
    }
    return filledData;
  };

  const displayedAthletes = fillEmptyCards(pickedAthletes, 10);

  return (
    <React.Fragment>
      <AthleteProfile open={openAthleteProfile} setOpen={setOpenAthleteProfile} athleteId={profileAthlete} />
      <Triathletes userid={userid} openSelectTriathletes={openSelectTriathletes} setOpenSelectTriathletes={setOpenSelectTriathletes} 
                   pickedAthletes={pickedAthletes} pickSelectedAthletes={pickSelectedAthletes} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ padding: 2, marginBottom: 2, height: 170 }}>
            <ActiveSelection eventInfo={eventInfo} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 2, marginBottom: 2, height: 170,
                  display: 'flex',            // Enable flexbox
                  flexDirection: 'column',    // Arrange content vertically
                  justifyContent: 'space-between', // Space out elements
                  alignItems: 'center',       // Center items horizontally
                }}  >
            <Typography variant="h6" component="div">
              <strong>Weekly Score:</strong> {pickedAthletes.reduce((sum, athlete) => sum + (athlete.pick_score || 0), 0)}
            </Typography>
            <Box sx={{  display: 'flex', gap: 5, }} >
              <Button variant="contained" color="primary" onClick={() => { setOpenSelectTriathletes(true) }} disabled={processing || eventInfo.locked} >
                Select Athletes
              </Button>
              <Button variant="contained" color="primary" onClick={autoPick} disabled={processing || eventInfo.locked} >
                AutoPick
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <StatusBar message={status.message} type={status.type} />
      <Grid container spacing={2}>
        {displayedAthletes.map((athlete, index) => (
          <Grid item xs={12} sm={6} md={6} key={athlete.id || index} sx={{ minWidth: 400 }}>
            <Card sx={{ height: 100,  flexDirection: 'column',}} >
              <CardContent>
                {athlete.name !== 'Not Selected' && (
                  <Grid container spacing={2}>
                    <Grid item xs={2} container justifyContent="center" alignItems="center">
                      <Avatar src={athlete.img_url} style={{ width: 80, height: 80 }} />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="body2"><b>Name:</b> {athlete.name}</Typography>
                      <Typography variant="body2"><b>Country:</b> {athlete.country}</Typography>
                      <Button size="small" variant="text" color="primary"
                        onClick={() => { setProfileAthlete(athlete.id); setOpenAthleteProfile(true) } } >
                      Full Profile
                      </Button>
                      <Button size="small" variant="text" color="primary" disabled={processing || eventInfo.locked} 
                        onClick={() => {handleRemoveAthlelte(athlete.id) }} >
                      Remove
                      </Button>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="body2"><b>52Week Score:</b> {athlete.score}</Typography>
                      <Typography variant="body2"><b>Weekly Rank:</b> {athlete.score_rank}</Typography>
                      <Typography variant="body2"><b>Weekly Score:</b> {athlete.pick_score}</Typography>
                      <Typography variant="body2"><b>Competions:</b> {athlete.on_start_list}</Typography>
                    </Grid>
                  </Grid>
                )}
                {athlete.name === 'Not Selected' && ( 
                  <Grid container spacing={2}>
                  <Grid item xs={2} container justifyContent="center" alignItems="center">
                    <Avatar 
                      sx={{
                        width: 80, 
                        height: 80, 
                        backgroundColor: '#f0f0f0',
                        cursor: processing || eventInfo.locked ? 'not-allowed' : 'pointer',
                        transition: "background-color 0.3s ease", // Smooth transition effect
                        "&:hover": { backgroundColor: '#c0c0c0' }, // Change background color on hover
                      }} 
                      disabled={processing || eventInfo.locked}  
                      onClick={() => setOpenSelectTriathletes(true)}
                    >
                      <AddCircleOutlineIcon  fontSize="large" color="primary"/>
                    </Avatar>
                  </Grid>
                  <Grid item xs={10} container  alignItems="center">
                    <Button size="large" variant="text" color="primary" disabled={processing || eventInfo.locked} 
                      onClick={() => { setOpenSelectTriathletes(true) } } >
                      <Typography variant="h6">Select Athlete</Typography>
                    </Button>
                  </Grid>
                </Grid>
                  )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}
